import $ from "jquery";
import "core-js/es/promise";
import "bootstrap";
import "../_imports/datatables/index";
import { handleDefaultError, handleError } from "../util";
import * as moment from 'moment';
import 'moment/locale/it';
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";

export const blockOptions = { overlayColor: "#FFF", opacity: 0.9 };

$.ajaxSetup({
    headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
});

$(document).ajaxError(function(event, jqXHR) {
    handleDefaultError(jqXHR);
});

($.fn.dataTable.ext.errMode as any) = function(settings, techNote, message) {
    console.warn(message);
    if (techNote === 7) {
        handleError({ status: 403 });
    }
};

moment.locale("it");

// options comuni Highcharts
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

const array_color = ["#01579B", "#BF360C", "#FF6F00", "#33691E", "#3E2723", "#455A64", "#4A148C", "#29B6F6", "#66BB6A", "#8D6E63"];
const font_family = "Poppins";

Highcharts.setOptions(
    {
        colors: array_color,
        credits: { enabled: false },
        lang: { thousandsSep: ".", decimalPoint: "," },
        chart: { style: { fontFamily: font_family }, },

        title:
        {
            style:
            {
                color: "#424242",
                fontWeight: "400",
                fontFamily: font_family
            }
        },

        tooltip:
        {
            style:
            {
                fontFamily: font_family,
                opacity: 1,
                backgroundColor: "#FFFFFF",
            },
            outside: true
        },

        legend:
        {
            itemStyle:
            {
                color: "#424242",
                fontFamily: font_family,
                fontSize: "11",
                fontWeight: "normal"
            },
            itemHoverStyle: { color: "#2BA6DF" },
            symbolHeight: 12,
            symbolWidth: 12,
            padding: 0
        },

        xAxis:
        {
            labels: { style: { fontFamily: font_family } },
            title: { style: { fontFamily: font_family } },
            lineColor: "#E0E0E0"
        },

        yAxis:
        {
            gridLineColor: "#F5F5F5",
            labels: { style: { fontFamily: font_family } },
            title: { style: { fontFamily: font_family } }
        },

        plotOptions:
        {
            series:
            {
                dataLabels:
                {
                    enabled: true,
                    style: { fontSize: "10", fontWeight: "normal" }
                }
            },

            bubble: { marker: { lineWidth: 2 } },

            column:
            {
                dataLabels:
                {
                    enabled: true,
                    color: "#FFFFFF",
                    padding: 1
                }
            },

            line: { lineWidth: 3 },
            spline: { lineWidth: 3 }
        },

        exporting:
        {
            enabled: false,
            chartOptions:
            {
                chart:
                {
                    style:
                    {
                        color: "#FFFFAA",
                        fontFamily: font_family
                    },
                },
            },
        },
    });
