import { Toast } from "./_imports/sweetalert2";
import i18n from "./lang";

export const block_options = { overlayColor: "#FFF", opacity: 0.9 };

export function delay(ms: number) {
    return new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    });
}

export function handleDefaultError(error: any) {
    switch (error.status) {
        case 401:
            Toast.fire({ icon: "error", title: i18n("Expired session, please log in again") });
            // Ritardo di 500ms per dare all'utente il tempo di leggere
            // il messaggio prima del redirect
            delay(500).then(() => window.location.reload());
            return true;
        case 500:
            Toast.fire({ icon: "error", title: i18n("Internal server error") });
            return true;
    }
    return false;
}

export function handleError(error: any) {
    switch (error.status) {
        case 403:
            Toast.fire({ icon: "error", title: i18n("You don't have permission") });
            break;
    }
}

export function stringifyUnprocessableEntityErrors(responseWithError: any, concatenateWith: string = "<br>") {
    if (!responseWithError["responseJSON"])
        return "";

    if (!responseWithError["responseJSON"]["errors"])
        return responseWithError["responseJSON"]["message"] ?? "";

    let errors: string[] = [];

    Object.keys(responseWithError["responseJSON"]["errors"]).forEach((key) => {
        errors.push(responseWithError["responseJSON"]["errors"][key]);
    });

    return errors.join(concatenateWith);
}

/**
 * Cerca "FAIL_message" in "FAIL_messages" e restituisce il relativo testo, altrimenti "default_text".
 *
 * @param FAIL_message Messaggi di errore restituito da AJAX.
 * @param FAIL_messages Array CHIAVE->VALORE con STRINGA_ERRORE->TESTO_DA_MOSTRARE.
 * @param default_text Messaggio da restituire se "FAIL_message" non è stata trovata in "FAIL_messages".
 *
 * @returns Messaggio di errore.
 */
export function getFAILMessage(FAIL_message: string, FAIL_messages: any, default_text: string): string {
    let text = ""; // testo da restituire

    // cerca in "FAIL_messages" "FAIL_message"
    for (const option_FAIL_message in FAIL_messages) if (option_FAIL_message === FAIL_message) text = FAIL_messages[option_FAIL_message];

    // testo se trovato, altrimenti "default_text"
    return text === "" ? default_text : text;
}
