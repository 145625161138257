import Swal, { SweetAlertOptions } from "sweetalert2";
export default Swal;

export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
});

export function SwalConfirm(fun, callback, options: SweetAlertOptions = {}) {
    let defaults: SweetAlertOptions = {
        title: "Sei sicuro?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sì",
        cancelButtonText: "No",
        reverseButtons: true,
    };

    // Deep merge defaults with incoming options
    $.extend(true, defaults, options);

    swalWithBootstrapButtons
        .fire(defaults)
        .then(result => {
            if (result.value) {
                fun().done(async (res)=>{
                    await swalWithBootstrapButtons.fire({
                        icon: "success",
                        title: "Operazione effettuata con successo",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    callback(res);
                }).fail((res)=>{
                    let errorMessage: string = res.responseJSON ? (
                        res.responseJSON["message"] ?? "Operazione non riuscita"
                    ) : "Operazione no riuscita";

                    Toast.fire({
                        title: errorMessage,
                        icon: "error",
                    });
                    callback(res);
                });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire("Operazione annullata", "", "error");
            }
        });
}
